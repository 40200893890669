@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --fora-primary-color: #e5dbd0;
  --fora-bg-color-secondary: #141414;
  --fora-text-color-main: #141414;
  --fora-error-color: #df5859;
  --fora-stroke-color: rgba(20, 20, 20, 0.1);
  --fora-main-holiday-color: #f3ebe2;
  --fora-disabled-secondary-color: #d0d0d0;
  --tw-ring-color: red;
  --tw-ring-offset-shadow: none;
  --fora-main-font: normal 400 15px/24px AktivGrotesk, Inter var, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --fora-start-top-animation: 0;
  --fora-color-secondary: #656260;
  --fora-main-background: #fff;
}

html,
body {
  padding: 0;
  margin: 0;
  font: var(--fora-main-font);
  background: var(--fora-main-background);
  color: #141414;
}

html {
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* remove chrome blue highlight on autfill input */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

/* Remove Safari left inner padding under macOS or the magnifying glass on the left under iOS
    Remove cancel button (Chrome & Safari)
 */
[type="search"]::-webkit-search-decoration,
[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}

@layer base {
  input[type="text"],
  input[type="number"],
  input[type="password"] {
    &:disabled {
      background-color: #efefef4c;
      opacity: 1;
      color: #545454;
    }
  }

  .container-1440 {
    max-width: calc(1440px - 16rem - 4rem);
  }

  .static-content ul {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    list-style-position: inside;
    list-style-type: revert;
    padding: revert;
  }

  .static-content ol {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    list-style-position: inside;
    list-style-type: decimal;
  }

  .static-content a {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity));
  }

  .static-content-notes a {
    word-break: break-all;
  }

  .static-content p {
    padding: 0 0 14px 0;
  }

  .static-content a:hover {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity));
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .static-content h1 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content h3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content-supplier ul {
    padding-left: 1.25rem;
    mpadding-right: 2.5rem;
    font-size: 15px;
    line-height: 24px;
    list-style-position: inside;
    list-style-type: revert;
  }

  .static-content-supplier ol {
    padding-left: 1.25rem;
    mpadding-right: 2.5rem;
    font-size: 15px;
    line-height: 24px;
    list-style-position: inside;
    list-style-type: decimal;
  }

  .static-content-commissions-and-contracts ul {
    @apply text-secondaryDark text-small;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    list-style-position: inside;
    list-style-type: revert;
    padding: revert;
  }

  .static-content-commissions-and-contracts ol {
    @apply text-secondaryDark text-small;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    list-style-position: inside;
    list-style-type: decimal;
  }

  .static-content-commissions-and-contracts a {
    @apply text-secondaryDark text-small;
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity));
  }

  .static-content-commissions-and-contracts p {
    @apply text-secondaryDark text-small;
  }

  .static-content-commissions-and-contracts a:hover {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity));
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .static-content-commissions-and-contracts h1 {
    @apply text-secondaryDark;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content-commissions-and-contracts h2 {
    @apply text-secondaryDark;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content-commissions-and-contracts h3 {
    @apply text-secondaryDark;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content-banner ul {
    list-style-position: inside;
    list-style-type: revert;
    padding-left: 0.5rem;
  }

  .static-content-banner ol {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    list-style-position: inside;
    list-style-type: decimal;
  }

  .static-content-banner a {
    --tw-text-opacity: 1;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .static-content-banner a:hover {
    --tw-text-opacity: 1;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .static-content-banner h1 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content-banner h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .static-content-banner h3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    line-height: 1.25;
  }

  /*img::before {*/
  /*  @apply bg-slate-200;*/
  /*}*/
  img::after {
    @apply text-sm text-gray-700;
  }

  pre {
    @apply block p-2 mb-1 text-sm text-gray-700 rounded-sm border whitespace-normal break-words;
    /*bg-slate-200 border-slate-300*/
  }

  .PhoneInput input[type="tel"] {
    @apply text-medium px-[14px] py-[10px] lg:px-3 lg:py-2 min-h-[48px] focus:ring-0 focus:border-main block w-full border-stroke rounded-r-xl placeholder:text-main/50;
  }

  .phone-input--error.PhoneInput input[type="tel"] {
    @apply focus:ring-0 focus:border-error block w-full border-error rounded-r placeholder-error focus:outline-none pr-[40px];
  }

  .autocomplete--suggestions {
    @apply shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md rounded-t-none border-t-0 absolute mt-[150px] top-0 bg-white py-2 z-20 max-h-[31rem] overflow-auto;

    &:empty {
      display: none;
    }
  }

  /*
    Note: This can be replaced by the following tailwind, if we determine this is only needed for the primary Checkbox component: 
    !bg-[length:13px]
    checked:!bg-[url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMiAxTDQuNjYyNSA4TDEgNC41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=)]
  */
  [type="checkbox"]:checked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMiAxTDQuNjYyNSA4TDEgNC41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
    background-size: 13px;
  }

  .rounded-checkbox.checked {
    @apply bg-[length:10px_10px] bg-center bg-no-repeat bg-[url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMiAxTDQuNjYyNSA4TDEgNC41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=)];
  }

  .font-groteskCd {
    font-family: AktivGroteskCd, Inter var, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  .font-grotesk {
    font-family: AktivGrotesk, Inter var, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  .font-kepler {
    font-family: KeplerStd, Inter var, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
}
@layer components {
  #__next {
    @apply h-full w-full;
  }

  .nav-active.text-white {
    @apply text-mainHoliday;
  }

  .nav-active .ph {
    @apply text-mainHoliday;
  }

  .nav-active svg {
    @apply text-mainHoliday;
  }

  .nav-active-holidays {
    /*@apply text-main;*/
    font-weight: bold;
    border-radius: 8px;
    background-color: #fbd2a3;
  }

  .nav-active-holidays .ph {
    @apply text-main;
  }

  .nav-active-holidays svg {
    @apply text-main;
  }

  *:focus {
    outline: 0;
  }

  [class*="btn"] {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .btn-primary {
    @apply inline-flex items-center justify-center py-2 px-4 text-medium font-medium rounded-button shadow-button text-white bg-main hover:bg-secondaryDark focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:bg-secondaryDark;
    text-shadow: 0px 2px 10px rgba(85, 85, 85, 0.25);
  }

  .btn-primary-outline {
    @apply inline-flex items-center py-2 px-4 border border-main text-main text-medium font-medium rounded-button bg-white hover:border-secondaryDark hover:text-secondaryDark focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:opacity-100 disabled:border-disabledSecondary disabled:text-disabledSecondary;
  }

  .btn-big {
    @apply px-6 py-3;
  }

  .btn-secondary {
    @apply inline-flex items-center justify-center py-2 px-4 border border-stroke text-medium font-medium rounded-button text-secondaryDark bg-white hover:text-secondaryDark/50 focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:bg-disabled;
  }

  .btn-text {
    @apply inline-flex items-center justify-center py-2 px-4 text-medium font-medium rounded-button text-secondaryDark bg-white hover:text-secondaryDark/50 focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:bg-disabled;
  }

  .btn-white {
    @apply inline-flex items-center border border-gray-300 shadow-sm text-sm leading-4 font-normal rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  .btn-success {
    @apply inline-flex items-center border border-transparent text-sm leading-4 font-normal rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500;
  }

  .btn-success-full {
    @apply inline-flex items-center py-2 px-4 text-medium font-medium rounded-button shadow-button text-white bg-success hover:bg-success/70 focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:bg-success/70;
  }

  .btn-danger {
    @apply inline-flex items-center border border-transparent text-sm leading-4 font-normal rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
  }

  .btn-secondary.disabled,
  .btn-text.disabled,
  .btn-white.disabled,
  .btn-success.disabled,
  .btn-danger.disabled,
  .btn-secondary[disabled],
  .btn-text[disabled],
  .btn-white[disabled],
  .btn-success[disabled],
  .btn-danger[disabled] {
    pointer-events: none;
    opacity: 0.65;
  }

  .btn-text.disabled,
  .btn-text[disabled] {
    background-color: transparent;
  }

  .btn-primary.disabled,
  .btn-primary[disabled] {
    pointer-events: none;
    @apply bg-disabled shadow-none opacity-100;
  }

  .form-control {
    @apply block w-full px-4 py-2.5 text-small border border-stroke rounded placeholder:text-main placeholder:opacity-50 focus:outline-none focus:ring-0 focus:border-main;

    &.form-control-icon {
      padding-right: 3rem;
    }
  }

  .form-control-icon + .form-control-icon--error-icon {
    margin-right: 40px;
  }

  .form-control[readonly] {
    @apply bg-gray-100 border-gray-300;
  }

  .form-control[type="file"] {
    @apply overflow-hidden w-auto max-w-full;
  }

  .form-control[type="file"]:not(:disabled):not([readonly]) {
    @apply cursor-pointer;
  }

  .form-control::file-selector-button {
    padding: 0.5rem 0.75rem;
    margin: -0.5rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3;
  }

  .form-control::-webkit-file-upload-button {
    padding: 0.5rem 0.75rem;
    margin: -0.5rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #374151;
    background-color: #f3f4f6;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3;
  }

  .form-control-v2 {
    @apply block w-full px-4 py-[11px] text-smallLH20 border border-stroke rounded placeholder:text-secondaryDark focus:outline-none focus:ring-0 focus:border-main;
  }

  .container-bookings-v2_1 {
    .form-control-v2 {
      @apply text-medium text-secondaryDark;
    }
  }

  .dropdown-button {
    @apply w-full inline-flex justify-between items-center bg-white hover:bg-gray-50 px-4 py-[11px] text-medium text-secondaryDark border border-stroke rounded-xl placeholder:text-secondaryDark focus:outline-none focus:ring-0 focus:border-main;
  }

  /*@media (min-width: 744px) {*/
  /*  .container-bookings-v2_1 {*/
  /*    .form-control-v2 {*/
  /*      @apply text-smallLH20 text-main py-[11px];*/
  /*    }*/
  /*  }*/
  /*}*/
  .checkbox-input {
    @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded;
  }

  .dropdown-aside .dropdown-menu {
    @apply hidden;
  }

  .dropdown-aside .dropdown-menu.show {
    @apply block !relative !transform-none;
  }

  .dropdown-toggle[aria-expanded="true"] .arrow {
    @apply text-gray-400 rotate-90;
  }

  .nav-tab-link {
    @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-normal text-sm;
  }

  .nav-tab-link.active {
    @apply border-indigo-500 text-indigo-600;
  }

  .nav-page-tabs-link {
    @apply border-transparent text-gray-900 hover:text-gray-800 hover:border-gray-200 whitespace-nowrap flex py-2 px-1 border-b-4 text-sm uppercase;
  }

  .nav-page-tabs-link.active {
    @apply border-gray-800 text-gray-800 font-medium;
  }

  .nav-page-tabs-link.current {
    @apply border-gray-800 text-gray-800 font-medium;
  }
  .action-button-bar {
    @apply flex items-center justify-end gap-2 border-t border-lightGreyBorder pt-4 -mx-6 px-4;
  }
}
@layer utilities {
  :disabled {
    @apply opacity-50;
  }

  .w-28r {
    width: 28rem;
  }

  .collapse:not(.show) {
    display: none;
  }
}
.modal-shadow {
  box-shadow: 0px 2px 10px rgba(181, 181, 181, 0.25) !important;
}

.modal-open {
  overflow: hidden;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal.fade {
  display: none;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal.show .modal-dialog {
  transform: none;
}
.alert-success {
  @apply bg-green-50;
}
.alert-success svg {
  @apply text-green-400;
}
.alert-success .message {
  @apply text-green-800;
}
.alert-warning {
  @apply bg-yellow-50;
}
.alert-warning svg {
  @apply text-yellow-400;
}
.alert-warning .message {
  @apply text-yellow-700;
}
.alert-danger {
  @apply bg-red-50;
}
.alert-danger svg {
  @apply text-red-400;
}
.alert-danger .message {
  @apply text-red-800;
}
.alert-info {
  @apply bg-blue-50;
}
.alert-info svg {
  @apply text-blue-400;
}
.alert-info .message {
  @apply text-blue-700;
}
.has-error .form-control,
.form-control.error,
.has-error select,
.has-error .select2-container--default .select2-selection--multiple,
.has-error .select2-container--default .select2-selection--single {
  @apply border-red-500;
}
.fade {
  @apply transition-opacity;
}
.fade:not(.show) {
  @apply opacity-0;
}
.tab-content > .tab-pane {
  @apply hidden;
}
.tab-content > .active {
  @apply block;
}

.bootbox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.bootbox .modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: flex;
  height: 100%;
}
@media (min-width: 576px) {
  .bootbox .modal-dialog {
    max-width: 540px;
    margin: auto;
  }
}

.bootbox.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.bootbox.show .modal-dialog {
  transform: translate(0, 0);
}
.bootbox .modal-content {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.5rem;
  outline: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.bootbox-body {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.bootbox .modal-footer {
  padding-top: 1.5rem;
}
.select2-container,
.select2-search__field {
  width: 100% !important;
}
.select2-container--default .select2-selection--multiple {
  @apply border-gray-300 rounded-md;
  min-height: 42px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNCAwQzQuMjY1MjIgNS45NjA0NmUtMDggNC41MTk1NyAwLjEwNTM1NyA0LjcwNzExIDAuMjkyODkzTDcuNzA3MTEgMy4yOTI4OUM4LjA5NzYzIDMuNjgzNDIgOC4wOTc2MyA0LjMxNjU4IDcuNzA3MTEgNC43MDcxMUM3LjMxNjU4IDUuMDk3NjMgNi42ODM0MiA1LjA5NzYzIDYuMjkyODkgNC43MDcxMUw0IDIuNDE0MjFMMS43MDcxMSA0LjcwNzExQzEuMzE2NTggNS4wOTc2MyAwLjY4MzQxNyA1LjA5NzYzIDAuMjkyODkzIDQuNzA3MTFDLTAuMDk3NjMxMSA0LjMxNjU4IC0wLjA5NzYzMSAzLjY4MzQyIDAuMjkyODkzIDMuMjkyODlMMy4yOTI4OSAwLjI5Mjg5M0MzLjQ4MDQzIDAuMTA1MzU3IDMuNzM0NzggMCA0IDBaTTAuMjkyODkzIDkuMjkyODlDMC42ODM0MTcgOC45MDIzNyAxLjMxNjU4IDguOTAyMzcgMS43MDcxMSA5LjI5Mjg5TDQgMTEuNTg1OEw2LjI5Mjg5IDkuMjkyODlDNi42ODM0MiA4LjkwMjM3IDcuMzE2NTggOC45MDIzNyA3LjcwNzExIDkuMjkyODlDOC4wOTc2MyA5LjY4MzQyIDguMDk3NjMgMTAuMzE2NiA3LjcwNzExIDEwLjcwNzFMNC43MDcxMSAxMy43MDcxQzQuMzE2NTggMTQuMDk3NiAzLjY4MzQyIDE0LjA5NzYgMy4yOTI4OSAxMy43MDcxTDAuMjkyODkzIDEwLjcwNzFDLTAuMDk3NjMxMSAxMC4zMTY2IC0wLjA5NzYzMTEgOS42ODM0MiAwLjI5Mjg5MyA5LjI5Mjg5WiIgZmlsbD0iIzlDQTNBRiIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: right 14px top 14px;
  padding-right: 28px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--single {
  @apply outline-none ring-indigo-500 border-indigo-500;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  @apply pl-3;
}
.select2-container .select2-search--inline .select2-search__field {
  @apply text-sm text-gray-500;
  margin-top: 10px;
}
.select2-container--default .select2-selection--single {
  @apply border-gray-300 rounded-md;
  height: 42px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  @apply text-sm text-gray-500;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
  @apply pl-3;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 9px;
  height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  @apply border-t-gray-400;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  @apply border-b-gray-400;
}
.select2-search__field {
  width: 100% !important;
}
/* The switch with checkbox */
.switch {
  @apply relative inline-block w-11 h-6;
}
.switch input {
  @apply w-0 h-0 opacity-0;
}
/* Right aside */
.aside-right {
  @apply w-28r max-w-full fixed top-0 right-0 duration-300 delay-1000 h-screen z-20;
  transform: translateX(100%);
  transition-property: transform;
}
.aside-right-backdrop {
  @apply fixed inset-0 bg-gray-500/50 opacity-0 invisible z-10;
}
.aside-right.is-shown + .aside-right-backdrop {
  opacity: 1;
  visibility: visible;
}
.aside-right.is-shown {
  transform: translateX(0);
  transition: transform 0.3s;
}
.aside-right > div {
  @apply relative;
}

.photoSwiper .swiper-slide {
  @apply !w-44 h-44 flex;
}
.photoSwiper .swiper-slide img {
  @apply rounded-lg object-cover;
}
.scroll-container {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  display: grid;
  grid: auto / auto-flow max-content;
  grid-gap: 10px;
  align-items: center;
}

.scroll-item {
  scroll-snap-align: center;
  @apply !w-44 h-44 flex;
}

.scroll-item img {
  @apply rounded-lg object-cover;
}

/* Datepicker */
.daterangepicker {
  font-family: "Inter var", serif;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  @apply bg-indigo-500;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  @apply border-gray-300 rounded-md shadow-sm pl-1;
}
.register-form > div {
  @apply mt-0;
}

.collapse-toggle[aria-expanded="true"] svg {
  transform: rotate(180deg);
}

/* Spinner */
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: #6366f1;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.breadcrumbs-container {
  li:first-child {
    margin-left: 0;
  }

  li:not(:first-child)::before {
    content: url("/img/chevron-right.svg");
    margin-right: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    display: inline-block;
    vertical-align: top; /* bottom also used */
    width: 20px;
    height: 20px;
    fill: #374151;
  }
}

.form-select-container,
.form-select-container-input {
  input:focus {
    box-shadow: none;
  }
}

.spin-loader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

@keyframes loadBg {
  to {
    background: rgba(0, 0, 0, 0.1);
  }
}

.PhoneInputCountry {
  align-items: center;
  background-color: #fff;
  border-color: rgba(20, 20, 20, 0.1);
  border-style: solid none solid solid;
  border-width: 1px;
  box-shadow: none;
  cursor: default;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: 48px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  border-radius: 12px 0 0 12px;
  margin: 0 !important;
  padding: 12px 11px 12px 16px;
}
.PhoneInputInput {
  font-size: 15px;
  line-height: 24px;

  &:placeholder {
    color: rgba(20, 20, 20, 0.5);
    font-weight: 400;
  }
}
.signup-content-container {
  .PhoneInputCountrySelectArrow {
    width: 8px;
    height: 8px;
    border-color: #141414;
    opacity: 1;
    margin: 0 5px 0 15px;
  }

  .autocomplete--suggestions {
    margin-top: 176px; /*Hotfix until profile is not redesigned */
  }
}
.PhoneInput--focus {
  .PhoneInputCountry {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #141414 !important;
    border-width: 1px;
    box-shadow: none !important;
    border-radius: 12px 0 0 12px;
  }
}

#outerContainer #mainContainer div.toolbar {
  display: none !important; /* hide PDF viewer toolbar */
}
#outerContainer #mainContainer #viewerContainer {
  top: 0 !important; /* move doc up into empty bar space */
}
.form-select-container {
  /* TODO rework with custom option of SelectFetch package*/

  > div + div > div > div:first-child {
    border-bottom: 1px solid #e5e7eb;
  }
}

img {
  /* styles for broken images */
  position: relative;
  overflow: hidden;
  color: transparent;
  background: transparent;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &::after {
    content: "";
    display: flex;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 640px) {
  .congrats-width {
    width: calc(100vw - 404px);
    margin: 0 auto;
  }
}

.checkbox-emotion {
  > span {
    @apply flex items-center justify-center w-[56px] h-[56px] bg-white rounded border border-stroke transition;
  }

  svg {
    will-change: transform;
  }

  .positive {
    @apply text-success;
  }

  .neutral {
    @apply text-mainHoliday;
  }

  .negative {
    @apply text-error;
  }

  &:hover {
    .positive {
      @apply border-success bg-successLight;

      svg {
        animation: rotateLeftToRight 2s infinite;
      }
    }

    .neutral {
      @apply border-mainHoliday bg-mainHoliday/20;

      svg {
        animation: upAndDown 2s ease infinite;
      }
    }

    .negative {
      @apply border-error bg-errorLight;

      svg {
        animation: leftToRight 1s linear infinite alternate-reverse;
      }
    }
  }

  input:checked {
    + .positive {
      @apply border-success bg-success text-white;

      svg {
        animation: rotateLeftToRight 2s;
      }
    }

    + .neutral {
      @apply border-mainHoliday bg-mainHoliday text-white;

      svg {
        animation: upAndDown 2s;
      }
    }

    + .negative {
      @apply border-error bg-error text-white;

      svg {
        animation: leftToRight 1s;
      }
    }
  }
}
@keyframes leftToRight {
  0% {
    transform: translate(-1px, 0);
  }
  100% {
    transform: translate(1px, 0) rotateY(30deg);
  }
}
@keyframes upAndDown {
  0% {
    transform: translate(0, -1px) rotateX(30deg);
  }
  50% {
    transform: translate(0, 2px);
  }
  100% {
    transform: translate(0, -1px) rotateX(30deg);
  }
}
@keyframes rotateLeftToRight {
  0% {
    transform: rotate(15deg) translate(0, 0);
  }
  50% {
    transform: rotate(-15deg) translate(0, -1px);
  }
  100% {
    transform: rotate(15deg) translate(0, 0);
  }
}

input.form-control-v2::-webkit-outer-spin-button,
input.form-control-v2::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.form-control-v2[type="number"] {
  -moz-appearance: textfield;
}

.form-control-percent {
  background: url("/img/percent.svg") 92% center no-repeat;
}

.form-select-container-down span:nth-child(2) + div div:nth-child(2)::after,
.form-select-container-up span:nth-child(2) + div div:nth-child(2)::after {
  content: "";
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0.5rem;
  font-size: 18px;
}

.form-select-container-down span:nth-child(2) + div div:nth-child(2)::after {
  background-image: url("../public/img/caret-down.svg");
}

.form-select-container-up span:nth-child(2) + div div:nth-child(2)::after {
  background-image: url("../public/img/caret-up.svg");
}

.radio-input {
  @apply shrink-0 w-5 h-5 border-secondaryDark focus:outline-none focus:ring-0 focus:ring-offset-0  focus:shadow-none;
}
.radio-input:checked {
  color: white;
  border-color: #21b98a !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNiIgY3k9IjYiIHI9IjUuNSIgZmlsbD0iIzIxQjk4QSIgc3Ryb2tlPSIjMjFCOThBIi8+Cjwvc3ZnPgo=");
  background-size: 12px;
}
.radio-input:checked + label {
  @apply font-medium text-main;
}

.pdfViewer .page {
  border: 0 none !important;
  background: none !important;
}
.mobile__pdf__container #viewerContainer .pdfViewer {
  overflow: auto !important;
}
.mobile__pdf__container #viewerContainer {
  top: 0 !important;
}
.mobile__pdf__container #loadingBar {
  display: none;
}
.mobile__pdf__container footer {
  position: absolute !important;
  height: 3rem !important;

  button,
  input {
    background-size: auto !important;
  }
}

.pdf-first-parent-zIndex {
  .rpv-default-layout__toolbar:first-of-type {
    z-index: 10;
  }
}
.rpv-core__menu .rpv-core__menu-divider:last-child {
  display: none;
}

.custom-m-6 {
  margin: 6px 0px 0px !important;
}

#react-select-timezone-listbox,
#react-select-country_id-listbox {
  margin-top: 1px !important;
  margin-bottom: 0px !important;
}

/*.form-select-container-open_up #react-select-country_id-listbox */
.form-select-container-open_up #react-select-timezone-listbox {
  position: absolute !important;
  top: -305px !important;
}

.custom-color {
  color: #141414;
}

.custom-width {
  width: 100%;
}
.react-datepicker__header--custom-content {
  .custom-fix-important-padding {
    padding: 0.25rem !important;
  }
}

/* Supplier Tab Navigation */

.supp-tab-navi-wrapper {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  scroll-snap-align: start;

  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar on webkit , firefox, IE, edge */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.supp-tab-navi-wrapper::-webkit-scrollbar {
  width: 0 !important;
}

.supp-tab-navi-item {
  scroll-snap-align: start;
}

/* width */
.scroll-narrow::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.scroll-narrow::-webkit-scrollbar-track {
  background: rgba(101, 98, 96, 0.3);
  border-radius: 8px;
  box-shadow: none;
  transition: 0.3s;
}

/* Handle */
.scroll-narrow::-webkit-scrollbar-thumb {
  background: rgba(101, 98, 96, 0.7);
  border-radius: 8px;
  transition: 0.3s;
}

/* Handle on hover */
.scroll-narrow::-webkit-scrollbar-thumb:hover {
  background: #656260;
  transition: 0.3s;
}

/* Scrollbar on hover class */
.scroll-narrow--onhover::-webkit-scrollbar-track {
  background: transparent;
}
.scroll-narrow--onhover::-webkit-scrollbar-thumb {
  background: transparent;
}
.scroll-narrow--onhover::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.scroll-narrow--onhover:hover::-webkit-scrollbar-track {
  background: rgba(101, 98, 96, 0.3);
}
.scroll-narrow--onhover:hover::-webkit-scrollbar-thumb {
  background: rgba(101, 98, 96, 0.7);
}
.scroll-narrow--onhover:hover::-webkit-scrollbar-thumb:hover {
  background: #656260;
}

/* width */
.scroll-narrow-clients::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border: 2px black;
}

/* Track */
.scroll-narrow-clients::-webkit-scrollbar-track {
  background: #faf8f8;
  transition: 0.3s;
  background-clip: content-box;
  box-shadow: inset 0 0 2px #e7e7e7;
}

/* Handle */
.scroll-narrow-clients::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 3px;
  transition: 0.3s;
  background-clip: content-box;
}

/* Handle on hover */
.scroll-narrow-clients::-webkit-scrollbar-thumb:hover {
  background: rgb(176, 176, 176);
  transition: 0.3s;
}

/* Scrollbar on hover class */
.scroll-narrow-clients--onhover::-webkit-scrollbar-track {
  background: transparent;
}
.scroll-narrow-clients--onhover::-webkit-scrollbar-thumb {
  background: transparent;
}
.scroll-narrow-clients--onhover::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.scroll-narrow-clients--onhover:hover::-webkit-scrollbar-track {
  background: rgba(101, 98, 96, 0.3);
}
.scroll-narrow-clients--onhover:hover::-webkit-scrollbar-thumb {
  background: rgba(101, 98, 96, 0.7);
}
.scroll-narrow-clients--onhover:hover::-webkit-scrollbar-thumb:hover {
  background: #656260;
}

.supplier-information-card {
  .resources-links {
    a {
      @apply hover:underline;
    }
  }

  .supplier-information-card-html-content {
    a {
      @apply text-link underline hover:no-underline;
    }
  }
}

.supplier-details-content {
  ul {
    @apply list-disc pl-5 pr-10 text-medium;
  }

  ol {
    @apply list-decimal pl-5 pr-10 text-medium;
  }
}
.supplier-details-when-to-book {
  a {
    @apply underline underline-offset-2 hover:no-underline;
  }
}

.deactivated {
  opacity: 0.5;
  pointer-events: none;
}
.warning-circle-adopted {
  fill: #fce8e8;
  color: #df5859;

  circle[opacity] {
    opacity: 1;
  }

  circle[stroke-width="16"] {
    stroke: #fce8e8;
    stroke-width: 10;
  }

  circle:last-child {
    /*'dot' figure*/
    fill: #df5859;
    r: 8;
  }
}

.ql-toolbar.ql-snow {
  border: 0 none !important;
  @apply w-full bg-lightGreyBg !p-4 flex gap-1 rounded-t-xl justify-between;

  .ql-formats {
    margin: 0 !important;
  }

  &:after {
    content: none !important;
  }
}

.ql-container.ql-snow {
  border: 0 none !important;
}
body .ql-container {
  font: var(--fora-main-font);
}
body .ql-editor {
  @apply p-0 text-medium overflow-hidden relative;
}
.quill {
  .ql-editor {
    @apply mt-4 px-4 pb-4 pt-0 max-h-[224px] overflow-y-auto scroll-narrow scroll-narrow--onhover;
  }
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  margin-right: 6px;
}
.ql-snow.ql-toolbar .ql-formats:last-child button {
  margin-right: 0;
}

.notes-teaser-see-less {
  @apply max-h-36;

  &::after {
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    content: "";
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%);
    pointer-events: none;
  }
}
.ql-editor.ql-blank::before {
  font-style: normal !important;
  @apply !text-tertiaryOld;
}
.hide-iframe iframe {
  visibility: hidden;
  height: 1px;
}

/* SVG loader */
.loader {
  position: relative;
  margin: auto;
  width: 72px;
  height: 72px;
}
.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}
.circular-loader-bg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 37, 98;
  stroke-dashoffset: -54;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.react-select__input {
  box-shadow: none !important;
}
.react-select__control {
  @apply dropdown-button;
}

/* Tooltip helpers */
.react-tooltip.opaque-tooltip {
  opacity: 1;
}

.dot-grid {
  background: transparent;
  background-image: radial-gradient(rgba(229, 231, 235, 1) 1px, transparent 0);
  background-size: 14px 14px;
  background-position: -19px -19px;
}

.break-words-custom {
  word-break: break-word;
}

.booking-details-modal {
  z-index: 5;

  .dialog-backdrop {
    display: none;
  }

  .dialog-scroll-container {
    @apply md:ml-[256px] mt-[60px] md:mt-4;
  }

  .dialog-centered-container {
    @apply pt-0 -mt-4 md:-mt-12;
  }
}

/*TODO create a separate style file for supplier dashboard pages and to include the specific classes*/
.non-supplier-details-about {
  @apply flex gap-2 pb-2 pt-3 border-b border-lightGreyBorder last:border-none last:pb-0 text-mediumLH22 font-medium empty:hidden;
}

.custom-text-indigo-shadow {
  text-shadow: 0 0 #3730a3;
}

.container-1056 {
  width: 100%;
  max-width: 1056px;
}

.booking-header-navigation-first-item {
  position: relative;
  margin-right: 16px;

  &:after {
    content: "";
    position: absolute;

    right: -16px;
    top: -8px;
    bottom: -8px;

    @apply border-r border-lightGreyBorder;
  }
}
.input-error {
  @apply border border-error text-error placeholder-error focus:outline-none focus:ring-0 focus:border-error;
}

.main-container-animation {
  animation: 1s ease-in 0s 1 reverse both running slidein;
}

@keyframes slidein {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.main-container-animation-reverse {
  transform: translate(100%);
  animation: 1s ease-in 0s 1 reverse both running slidein-reverse;
}
@keyframes slidein-reverse {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(100%);
  }
}
.fade-in {
  animation: 2s ease 2s 1 fadeIn;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out {
  animation: 2s ease 2s 1 fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.layout-space-compensation-pbmff {
  @apply -mt-4 first:-mt-6 -mx-6 md:-mx-16 lg:mb-8 lg:my-auto lg:mx-auto;
}

/*
  Skeleton Pulsing Animation
*/
@keyframes pulse {
  0% {
    background-color: #eff2f6;
  }
  50% {
    background-color: #dde3ed;
  }
  100% {
    background-color: #eff2f6;
  }
}

.skeleton-loading-animation {
  animation: pulse 3s ease-in-out infinite;
  width: 100%;
  display: inline-block;
}

@keyframes wave {
  0% {
    background-color: #eff2f6;
  }
  100% {
    background-color: #dde3ed;
  }
}
.skeleton-loading-animation-wave {
  animation: wave 3s linear infinite;
  width: 100%;
  display: inline-block;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.bookings-platform-mobile-sorting .dialog-centered-container {
  @apply !items-start pt-[228px];
}

.currency-info-banner {
  circle[opacity] {
    opacity: 1;
  }
  circle:last-child {
    fill: #fff;
  }
}

/*
  Styling for HTML that is converted from markdown by react-markdown.
  You must pass className="markdown" to the react-markdown component to apply these styles.
*/

@layer components {
  .markdown ul {
    @apply list-disc pl-8;
  }
  .markdown ol {
    @apply list-decimal pl-8;
  }
  .markdown blockquote {
    @apply border-l-4 pl-4 border-mainHoliday text-secondaryDark my-4;
  }
  .markdown h1 {
    @apply text-headerFS24 font-medium text-main my-4;
  }
  .markdown h2 {
    @apply text-header font-medium text-main my-3;
  }
  .markdown h3 {
    @apply text-staticMobileTitle font-medium text-main my-2;
  }
  .markdown h4,
  .markdown h5,
  .markdown h6 {
    @apply font-medium text-main my-2;
  }
  .markdown table {
    @apply w-full border-collapse my-4;
  }
  .markdown td {
    @apply border border-stroke p-2;
  }
  .markdown th {
    @apply border border-stroke p-2 bg-mainHoliday;
  }
  .markdown a {
    @apply text-link hover:underline;
  }
}

.form-help br {
  content: "";
  @apply block mt-2;
}

.impersonate-wrapper {
  background: #cc3333;
}
.rates-sidepanel-animation {
  animation: sidepanel 1s ease-in-out;
}
@keyframes sidepanel {
  0% {
    top: var(--fora-start-top-animation);
  }
  100% {
    top: 900px;
  }
}

.rotateY-180 {
  transform: rotateY(180deg);
}

.text-ai-formatted {
  font-size: 13px;
  line-height: 20px;
}

.text-ai-formatted ul {
  list-style: disc;
  padding-left: 1.5rem;
}
.text-ai-formatted ol {
  list-style: auto;
  padding-left: 1.5rem;
}
.text-ai-formatted.two-columns ul,
.text-ai-formatted.two-columns ol {
  column-count: 2;
  column-gap: 1.5rem;
}
.text-ai-formatted.two-columns ul li,
.text-ai-formatted.two-columns ol li {
  break-inside: avoid-column;
}

.desktop-dropdown {
  @apply absolute top-1 rounded-[12px] bg-white border border-primary shadow-popover p-4 z-40;
}

/* React Quill presets */
.quill {
  @apply bg-white border border-lightGreyBorder rounded-xl;
  > .ql-toolbar {
    /* Tweaks spacing between toolbar sections */
    @apply justify-start gap-x-2 modalFS:gap-x-6;
  }
}

/* Clients page - An input in a group that repeats, separated by space and a line */
.client-field-repeat-input {
  @apply gap-6;

  &:not(:first-child) {
    @apply pt-6 border-t border-lightGreyBorder;
  }
}

/* A button styled like a text link */
.link-button {
  @apply text-link cursor-pointer h-fit rounded-md hover:underline focus:ring focus:underline;
}

.cancellation-policy-title {
  @apply text-smallLH18 font-medium;
}
.rainbow-hover-bg {
  &:hover,
  &:focus {
    @apply bg-[linear-gradient(249deg,#FFE600,#FFFFFF00),linear-gradient(158deg,#FFFFFF00,#FF990066),linear-gradient(130deg,#6200FF,#F4F5F8)];
  }
}
/* React Swiper overrides */
.swiper-pagination-bullet {
  background-color: #fff !important;
  width: 6px !important;
  height: 6px !important;
  opacity: 0.6 !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.loading-dots-light {
  margin: 0 12px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  animation: flash-white 0.75s ease-out infinite alternate;

  @keyframes flash-white {
    0% {
      background-color: #fff2;
      box-shadow: 12px 0 #fff2, -12px 0 #fff;
    }
    50% {
      background-color: #fff;
      box-shadow: 12px 0 #fff2, -12px 0 #fff2;
    }
    100% {
      background-color: #fff2;
      box-shadow: 12px 0 #fff, -12px 0 #fff2;
    }
  }
}

.loading-dots-dark {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 12px;
  margin-right: 12px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  animation: flash-black 0.75s ease-out infinite alternate;

  @keyframes flash-black {
    0% {
      background-color: #b3b2b1;
      box-shadow: 12px 0 #b3b2b1, -12px 0 #656260;
    }
    50% {
      background-color: #676462;
      box-shadow: 12px 0 #b3b2b1, -12px 0 #b3b2b1;
    }
    100% {
      background-color: #b3b2b1;
      box-shadow: 12px 0 #656260, -12px 0 #b3b2b1;
    }
  }

  &.large {
    margin-left: auto;
    margin-right: auto;
    margin-top: inherit;
    margin-bottom: inherit;
    width: 12px;
    height: 12px;
    animation: flash-black-lg 0.75s ease-out infinite alternate;

    @keyframes flash-black-lg {
      0% {
        background-color: #b3b2b1;
        box-shadow: 24px 0 #b3b2b1, -24px 0 #656260;
      }
      50% {
        background-color: #676462;
        box-shadow: 24px 0 #b3b2b1, -24px 0 #b3b2b1;
      }
      100% {
        background-color: #b3b2b1;
        box-shadow: 24px 0 #656260, -24px 0 #b3b2b1;
      }
    }
  }
}

.splide__arrow--prev:disabled:has(+ .splide__arrow--next:disabled),
.splide__arrow--prev:disabled + .splide__arrow--next:disabled {
  display: none;
}

.bp-navigation {
  .swiper-slide {
    width: auto !important;
    height: auto !important;
    margin-right: 40px !important;

    &:last-of-type {
      margin-right: 0 !important;
    }
  }
}
/* React map-gl overrides */
.bp-map-popup {
  .mapboxgl-popup-content {
    @apply bg-transparent;
    box-shadow: none;
    padding: 0;
  }
  .mapboxgl-popup-close-button {
    display: none;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
}
.ql-editor-container--property-report {
  .quill {
    .ql-editor {
      max-height: none;
      height: 240px;
      @media (min-width: 640px) {
        height: 287px;
      }
    }
  }
}
/* Uppy File Uploader overrides */
.uppy-Container,
.uppy-Root,
.uppy-Dashboard,
.uppy-Dashboard-inner {
  height: 100% !important; /* Ensure it takes 100% of its parent container's height */
  min-height: unset !important; /* Remove the default min-height */
}
.uppy-Container {
  padding-bottom: 8px;
}
.uppy-Dashboard-inner {
  border-radius: 12px !important;
}
.uppy-DashboardContent-bar {
  border-radius: 12px 12px 0 0 !important;
}
.uppy-DashboardContent-title {
  @apply text-secondary;
}
.uppy-DashboardContent-addMore {
  background: var(--color-bg-secondary, rgba(0, 0, 0, 0.08)) !important;
  color: var(--color-text-primary) !important;
  font-size: 13px !important;
  border-radius: 12px;
}
.uppy-DashboardContent-back {
  color: var(--color-text-primary) !important;
  font-size: 13px !important;
}
.uppy-DashboardContent-back:focus {
  background: transparent;
  background-color: transparent;
}
.uppy-Dashboard-Item-previewInnerWrap {
  border-radius: 12px !important;
}
.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile.uppy-size--height-md)
  .uppy-Dashboard-Item-action--remove {
  height: 32px;
  display: flex;
  padding: 3px;
  width: 32px;
  align-items: center;
  justify-content: center;
}
.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile.uppy-size--height-md)
  .uppy-Dashboard-Item-actionWrapper {
  margin-right: 6px;
}
.uppy-size--md .uppy-Dashboard-Item-action--remove {
  top: 16px;
  left: 12px;
}
button.uppy-Dashboard-Item-action--remove:focus {
  box-shadow: none;
}
button.uppy-Dashboard-Item-action--remove > svg {
  visibility: hidden !important;
}
button.file-manager-remove,
button.uppy-Dashboard-Item-action--remove:after {
  position: absolute;
  content: url("/img/file-uploader-close-x.svg");
  @apply bg-white border border-borderGray absolute rounded-lg flex items-center justify-center pt-[1px];
  height: 31px;
  padding: 6px;
  width: 32px;
}
/* tailwind config didnt work if we generate classnames dynamically
zIndex: {
   "1": "1",
   "2": "2",
   "3": "3"
}*/

.uppy-Container.uppy-Container-blur {
  position: relative;
}
.uppy-Container.uppy-Container-blur:before {
  content: "One moment...";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-bottom: 8px;
  backdrop-filter: blur(2px);
  z-index: 2000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  @apply absolute flex flex-col overflow-auto bg-white/20 rounded text-xl;
}

.z-3 {
  z-index: 3;
}
.z-2 {
  z-index: 2;
}
.z-1 {
  z-index: 1;
}

.caret-transparent {
  caret-color: transparent;
}

html,
body {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
}
